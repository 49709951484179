
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';

function generateAlias(str) {
    str = str.toLowerCase();
    str = str.replace(/\s\s+/g, ' ');
    str = str.replace(/ /g, '-');
    str = str.replace(/\./g, '-');
    str = str.replace(/\,/g, '-');
    str = str.replace(/š/g, 's');
    str = str.replace(/č/g, 'c');
    str = str.replace(/ć/g, 'c');
    str = str.replace(/đ/g, 'dj');
    str = str.replace(/ž/g, 'z');
    return str;
}

class QuantityDiscount extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            initial: false,
            value: []
        };
    }

    componentDidMount() {
        this.setInitialValue()
    }
    componentDidUpdate(prevProps) {
        if (prevProps.value != this.props.value && !this.state.initial) {
            this.setInitialValue()
        }
    }

    setInitialValue = () => {
        if (this.props.value) {
            this.setState({ value: this.props.value, initial: true })
        }
    }


    addItem = () => {
        let value = this.state.value;

        let obj = {
            quantity: '',
            discount: ''
        }
        value.push(obj);
        this.setState({ value }, () => {
            this.save()
        })
    }
    removeItem = (idx) => {
        let value = this.state.value;
        value.splice(idx, 1)
        this.setState({ value }, () => {
            this.save()
        })
    }
    save = () => {
        let value = this.state.value;
        this.props.onChange(value)
    }


    render() {
        return (
            <div className='quantity-discount-field-wrap'>
                <div className={this.state.modal ? 'quantity-discount-button active-button' : 'quantity-discount-button'} onClick={() => this.setState({ modal: true })}>
                    {this.props.label ? this.props.label : 'Popust na kolicinu'}
                </div>

                {this.state.modal ? <div className='quantity-discount-modal-overlay' onClick={() => this.setState({ modal: false })} /> : null}

                {
                    this.state.modal ?
                        <div className='quantity-discount-modal-wrap'>
                            <div className='quantity-discount-modal-header'>
                                <h6>Popust na kolicinu</h6>
                                <div onClick={() => this.setState({ modal: false })}>&times;</div>
                            </div>
                            <div className='quantity-discount-modal-body'>
                                {
                                    this.state.value && this.state.value.length ?
                                        this.state.value.map((item, idx) => {
                                            return (
                                                <div className='quantity-discount-item-wrap' key={idx}>
                                                    <div className='quantity-discount-item-inputs'>
                                                        <div className='quantity-discount-item-input'>
                                                            <label>Kolicina</label>
                                                            <input value={item.quantity} type='text' onChange={(e) => {
                                                                let reg = /^\d+$/;
                                                                let val = e.target.value;
                                                                let check = true;
                                                                if (val && !reg.test(val)) {
                                                                    check = false;
                                                                }
                                                                if (check) {
                                                                    let value = this.state.value;
                                                                    value[idx].quantity = e.target.value;
                                                                    this.setState({ value }, () => {
                                                                        this.save()
                                                                    })
                                                                }

                                                            }} />
                                                        </div>
                                                        <div className='quantity-discount-item-input'>
                                                            <label>Popust (%)</label>
                                                            <input value={item.discount} type='text' onChange={(e) => {
                                                                let reg = /^\d+$/;
                                                                let val = e.target.value;
                                                                let check = true;
                                                                if (val && !reg.test(val)) {
                                                                    check = false;
                                                                }
                                                                if (check) {
                                                                    let value = this.state.value;
                                                                    value[idx].discount = e.target.value;
                                                                    this.setState({ value }, () => {
                                                                        this.save()
                                                                    })
                                                                }

                                                            }} />
                                                        </div>
                                                    </div>

                                                    <div className='remove-item' onClick={() => this.removeItem(idx)}>
                                                        &times;
                                                    </div>
                                                </div>
                                            )
                                        })
                                        :
                                        null
                                }
                            </div>

                            <div className='quantity-discount-modal-footer'>
                                <button type='button' onClick={() => this.addItem()}>Dodaj</button>
                            </div>
                        </div>
                        :
                        null
                }
            </div>
        );

    }
}

export default QuantityDiscount;