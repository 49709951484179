
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';

class Check extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <div onClick={() => this.props.onChange(!this.props.value)} className="table-checkbox">
                <div class={this.props.value ? "checkbox checked" : "checkbox"} ></div>
                <span>{this.props.translate(this.props.label)}</span>

            </div>
        );
    }
}

export default Check;