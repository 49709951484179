
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';

import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Input
} from 'reactstrap';

class DatePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <div className="input-wrap">
                {this.props.label ? <label>{this.props.translate(this.props.label)}</label> : null}

                <Input type='date' value={this.props.value} onChange={this.props.onChange}/>
            </div>
        );
    }
}

export default DatePicker;